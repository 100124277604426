import React from 'react'
import { TopIntro } from '$components'
import { Col } from '../components/TopIntro'
import {
  Testimonial,
  Ramen,
  Offering,
  Content,
  Space,
  Body,
  Grid,
  GridCol,
  Profile,
  Learning,
  Contact,
} from '../components/About'
import Layout from '../components/layout'
import SEO from '../components/seo'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

const About = () => {
  const data = useStaticQuery(
    graphql`
      query {
        topImage: file(relativePath: { eq: "Ramen-bel@2x.png" }) {
          childImageSharp {
            fluid(maxWidth: 755, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }

        space: file(relativePath: { eq: "Space-bel@2x.png" }) {
          childImageSharp {
            fluid(maxWidth: 640, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }

        profile: file(relativePath: { eq: "bel-profile.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 755, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
    `
  )

  return (
    <Layout>
      <SEO title="About" />

      <TopIntro variation="aboutColor">
        <Col>
          <h1>about</h1>

          <p>
          Hey, I’m bel! and I want to create bloody brilliant motion design.
          </p>

          <p>
          It's my goal to produce award winning animations for agencies and
          companies who have positive cultural and social impact.
          </p>

          <p>
            I have experience in all phases of the production process, from idea
            mapping, concepts, storyboarding, designing and illustrating,
            animating and editing.
          </p>
        </Col>

        <Col>
          <Ramen
            fluid={data.topImage.childImageSharp.fluid}
            alt="I love noodles!"
          />
        </Col>
      </TopIntro>

      <Testimonial>
        <figure>
          <blockquote>
            <p>
              We're always impressed with Bel's ability to translate our ideas
              into beautifully animated stories that captivate and effectively
              get the point across.
            </p>
          </blockquote>
          <figcaption>Flavio Argemi, Creative Director, Avenue</figcaption>
        </figure>
      </Testimonial>

      <Offering>
        <Content>
          <GridCol left>
            <Space fluid={data.space.childImageSharp.fluid} />
          </GridCol>
          <GridCol>
            <Body>
              <h3>Think of me as one of your team!</h3>

              <p>
                The best ideas and results come from teamwork, during our
                collaboration I will:
              </p>

              <ul>
                <li>
                  Listen and ask questions to help identify and understand your
                  creative challenges.
                </li>
                <li>
                  Communicate clearly and use effective proccesses to keep the
                  job moving!
                </li>
                <li>
                  Produce high quality work to strict brand guidelines on time
                  and on budget.
                </li>
              </ul>
            </Body>
          </GridCol>
        </Content>
      </Offering>

      <Grid>
        <GridCol left>
          <Learning>
            <div>
              <h3>Forever in pursuit of the eye-gasm</h3>
              <p>
              The very nature of motion design requires commitment and perseverance. Luckily I love it! Discomfort and challenge means I’m learning and growing. I’m constantly pushing myself to create work I’m proud of and love to look at!
              </p>
            </div>
          </Learning>
          <Contact>
            <a href="mailto:me@belgiles.com">get in touch!</a>
          </Contact>
        </GridCol>

        <GridCol>
          <Profile fluid={data.profile.childImageSharp.fluid} />
        </GridCol>
      </Grid>
    </Layout>
  )
}

export default About
