import styled from 'styled-components'
import Img from 'gatsby-image'

export const Testimonial = styled.div`
  width: 100%;
  background: #019755;
  padding: 17% 0;
  position: relative;
  z-index: 100;

  @media (min-width: 768px) {
    padding: 10% 0;
  }

  @media (min-width: 1024px) {
    padding: 140px 0 125px;
  }

  figure {
    margin: 0 30px;
    @media (min-width: 768px) {
      max-width: 1074px;
      margin-left: auto;
      margin-right: auto;
      padding: 0 30px;
    }

    @media (min-width: 1220px) {
      padding: 0;
      max-width: 1200px;
    }
  }

  blockquote {
    padding: 0;
    margin: 0;
  }

  blockquote p {
    color: #ffbe1d;
    font-family: ${({ theme }) => theme.headingFont};
    font-size: 23px;
    font-weight: 700;
    line-height: 1.26;
    margin: 0;
    margin-bottom: 15px;
    position: relative;

    &:before {
      content: '“ ';
      position: absolute;
      left: -18px;
      top: 0;

      @media (min-width: 1024px) {
        left: -25px;
      }
      @media (min-width: 1220px) {
        left: -33px;
      }
    }
    &:after {
      content: '”';
    }

    @media (min-width: 768px) {
    }

    @media (min-width: 1024px) {
      font-size: 36px;
    }
    @media (min-width: 1220px) {
      font-size: 48px;
      margin-bottom: 5px;
    }
  }

  figcaption {
    font-family: ${({ theme }) => theme.headingFont};
    font-size: 14px;
    font-weight: 700;
    color: #ffbe1d;

    @media (min-width: 1024px) {
      text-align: right;
      margin-right: 53px;
      font-size: 20px;
    }
  }
`

export const Contact = styled.div`
  background: #ffbe1d;
  position: relative;
  display: flex;
  padding: 30px 0;
  justify-content: center;
  align-items: center;

  &:hover {
    background: #019755;

    a {
      color: #ffbe1d;
    }
  }

  @media (min-width: 768px) {
    justify-content: flex-start;
    padding-left: 30px;
  }

  @media (min-width: 1280px) {
    padding: 0;
  }

  a {
    color: #019755;
    font-family: ${({ theme }) => theme.headingFont};
    font-weight: 700;
    font-size: 36px;
    text-decoration: none;

    &:hover {
    }

    &:after {
      position: absolute;
      content: '';
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    }

    @media (min-width: 1024px) {
      font-size: 70px;
    }
    @media (min-width: 1440px) {
      font-size: 90px;
    }
  }

  @media (min-width: 768px) {
    height: 36%;
    &:before {
      position: absolute;
      width: 3000px;
      right: 100%;
      height: 100%;
      top: 0;
      content: '';
      background: inherit;
    }
  }
`

export const Profile = styled(Img)`
  height: 100%;

  @media (min-width: 1200px) {
    width: calc(100% + ((100vw - 1200px) / 2));
    max-width: 818px;
    overflow: visible !important;

    &:before {
      content: '';
      position: absolute;
      background: #60d3f4;
      height: 100%;
      left: 100%;
      top: 0;
      width: 3000px;
    }
  }
`

export const Content = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  @media (min-width: 768px) {
    display: flex;
  }
`

export const Space = styled(Img)`
  max-width: 640px;
  margin: 0 auto;
  z-index: 10;
  

  @media (min-width: 768px) {
    margin: 0;
    margin-bottom: -30px;
    

  }

  @media (min-width: 1280px) {
    margin-bottom: -44px;
  }
`
export const Grid = styled.div`
  max-width: 1200px;
  margin: 0 auto;

  @media (min-width: 768px) {
    display: flex;
  }
`

export const Body = styled.div`
  color: #e8d8d9;
  padding: 20px 30px 28px;

  @media (min-width: 768px) {
      padding-top: 50px;
      padding-bottom: 50px;
      padding-left: 0;
    }

    @media (min-width: 1024px) {
      padding: 80px 30px 30px;
    }

    @media (min-width: 1280px) {
      padding-left: 0;
      padding-right: 50px;
      width: calc(100% + 20px);
    }

  ul {
    list-style: none;
    padding: 0;
  }

  li, p {
    line-height: 1.5;
    margin-bottom: 17px;
    padding-left: 23px;
    position: relative;

    &:before {
      height: 4px;
      width: 4px;
      background: #e8d8d9;
      border-radius: 50%;
      position: absolute;
      top: 10px;
      left: 0;
      content: '';
      position: absolute;
    }

    @media (min-width: 1024px) {
      margin-bottom: 27px;
    }
  }

  p {
    padding-left: 0;
    &:before {
      content: none;
    }
  }

  h3 {
    color: #60d3f4;
    font-size: 36px;
    margin: 0 0 20px;
    line-height: 1.05;

    @media (min-width: 1024px) {
      margin-bottom: 25px;
      font-size: 50px;

    }

    @media (min-width: 1200px) {
      font-size: 60px;
      padding-right: 50px;
    }

    
  }
`

export const Offering = styled.div`
  background: #26252d;
`

export const GridCol = styled.div`
  position: relative;

  @media (min-width: 768px) {
    width: ${props => (props.left ? '60%' : '40%')};
    display: ${props => (props.left ? 'flex' : 'block')};
    flex-direction: column;
  }
  @media (min-width: 1024px) {
    ${'' /* width: ${props => (props.left ? '66.666%' : '33.3333%')}; */}
  }
`

export const Learning = styled.div`
  background: #f790ce;
  position: relative;
  padding: 37px 30px;

  @media (min-width: 768px) {
    display: flex;
    align-items: center;
  }

  @media (min-width: 1024px) {
    padding-right: 50px;
    padding-top: 100px;
    padding-bottom: 100px; 
  }

  @media (min-width: 1280px) {
    padding-right: 100px;
    padding-left: 0;
  }
  h3 {
    margin: 0;
    line-height: 1.05;
    color: #fb500d;
    font-size: 38px;
    font-family: ${({ theme }) => theme.headingFont};
    font-weight: 700;

    @media (min-width: 768px) {
      margin-top: 20px;
    }

    @media (min-width: 1024px) {
      font-size: 50px;
      line-height: 1;
      margin-top: 0;
    }
  }

  p {
    line-height: 1.6;
    margin: 20px 0;

    @media (min-width: 768px) {
      margin-bottom: 40px;
    }

    @media (min-width: 1024px) {
      margin-top: 30px;
      margin-bottom: 0;
    }
  }

  @media (min-width: 768px) {
    min-height: 64%;
    &:before {
      position: absolute;
      width: 3000px;
      right: 100%;
      height: 100%;
      top: 0;
      content: '';
      background: inherit;
    }
  }
`
export const Ramen = styled(Img)`
  max-width: 500px;
  margin: 0 auto;
  margin-left: -30px;
  margin-right: -30px;
  margin-bottom: calc(100vw * -0.145);

  @media (min-width: 500px) {
    margin: 0;
    margin-bottom: -74px;
  }
  @media (min-width: 768px) {
    margin-bottom: 0;
    max-width: 755px;
    width: 100%;
    position: absolute !important;
    bottom: calc(100vw * -0.07);
    left: -30px;
  }

  @media (min-width: 1200px) {
    width: 630px;
    bottom: -93px;
  }
  @media (min-width: 1440px) {
    width: 755px;
    bottom: -112px;
  }
`
